import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'

const Home: NextPage = () => {
  return (
    <React.Fragment>
      <Head>
        <title>European Society for Positive Psychology</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {/* <div className="light scroll-smooth">
        <div className="font-nunito text-base text-black dark:text-white dark:bg-slate-900"> */}
      <Layout>
        <section className="relative table w-full py-36 lg:py-44 bg-indigo-600/5 overflow-hidden">
          <div className="container">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px] relative">
              <div className="md:col-span-7">
                <h4
                  className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
                  Learn with <span className="text-indigo-600">Expert</span> <br /> Anytime & Anywhere</h4>
                <p className="text-slate-400 text-lg max-w-xl">Launch your campaign and benefit from our expertise on
                  designing and managing conversion centered Tailwind CSS v3.x html page.</p>

                <div className="mt-6">
                  <a href=""><img src="/assets/images/app/app.png" className="inline-block m-1" alt="" /></a>
                  <a href=""><img src="/assets/images/app/playstore.png" className="inline-block m-1" alt="" /></a>
                </div>
              </div>

              <div className="md:col-span-5">
                <div className="relative">
                  <img src="/assets/images/course/hero.png" alt="" />

                  <div className="absolute bottom-2/4 translate-y-2/4 right-0 left-0 text-center">
                    <a href="#!" data-type="youtube" data-id="yba7hPeTSjk"
                      className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                      <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="overflow-hidden after:content-[''] after:absolute after:h-32 after:w-32 after:bg-red-600/5 after:top-10 ltr:after:left-0 rtl:after:right-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]">
              </div>
              <div
                className="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-indigo-600/5 after:top-0 ltr:after:-right-5 rtl:after:-left-5 after:-z-1 after:rounded-full">
              </div>
            </div>
          </div>
        </section>



        <section className="py-6 border-b border-gray-100 dark:border-gray-800">
          <div className="container">
            <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
              <div className="mx-auto py-4">
                <img src="/assets/images/client/amazon.svg" className="h-6" alt="" />
              </div>

              <div className="mx-auto py-4">
                <img src="/assets/images/client/google.svg" className="h-6" alt="" />
              </div>

              <div className="mx-auto py-4">
                <img src="/assets/images/client/lenovo.svg" className="h-6" alt="" />
              </div>

              <div className="mx-auto py-4">
                <img src="/assets/images/client/paypal.svg" className="h-6" alt="" />
              </div>

              <div className="mx-auto py-4">
                <img src="/assets/images/client/shopify.svg" className="h-6" alt="" />
              </div>

              <div className="mx-auto py-4">
                <img src="/assets/images/client/spotify.svg" className="h-6" alt="" />
              </div>
            </div>
          </div>
        </section>



        <section className="relative md:py-24 py-16 overflow-hidden">
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Core Features</h3>
              <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you
                need to generate awareness, drive traffic, connect.</p>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">

              <div
                className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                <div className="relative overflow-hidden text-transparent -m-3">
                  <i data-feather="hexagon" className="h-32 w-32 fill-indigo-600/5 mx-auto"></i>
                  <div
                    className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                    <i className="uil uil-fire"></i>
                  </div>
                </div>

                <div className="mt-6">
                  <a href=""
                    className="text-xl font-medium transition-all duration-500 ease-in-out hover:text-indigo-600">Faster</a>
                  <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">If the distribution of
                    letters and 'words' is random, the reader will not be distracted from making.</p>
                </div>
              </div>



              <div
                className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                <div className="relative overflow-hidden text-transparent -m-3">
                  <i data-feather="hexagon" className="h-32 w-32 fill-indigo-600/5 mx-auto"></i>
                  <div
                    className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                    <i className="uil uil-wallet"></i>
                  </div>
                </div>

                <div className="mt-6">
                  <a href=""
                    className="text-xl font-medium transition-all duration-500 ease-in-out hover:text-indigo-600">Cheaper</a>
                  <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">If the distribution of
                    letters and 'words' is random, the reader will not be distracted from making.</p>
                </div>
              </div>



              <div
                className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                <div className="relative overflow-hidden text-transparent -m-3">
                  <i data-feather="hexagon" className="h-32 w-32 fill-indigo-600/5 mx-auto"></i>
                  <div
                    className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-indigo-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                    <i className="uil uil-comment-lines"></i>
                  </div>
                </div>

                <div className="mt-6">
                  <a href=""
                    className="text-xl font-medium transition-all duration-500 ease-in-out hover:text-indigo-600">At
                    Hand</a>
                  <p className="text-slate-400 transition-all duration-500 ease-in-out mt-3">If the distribution of
                    letters and 'words' is random, the reader will not be distracted from making.</p>
                </div>
              </div>

            </div>
          </div>

          <div className="container md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-5 md:col-span-6">
                <div className="grid grid-cols-12 gap-6 items-center relative">
                  <div className="col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <img src="/assets/images/about/ab03.jpg" className="shadow rounded-md" alt="" />
                      <img src="/assets/images/about/ab02.jpg" className="shadow rounded-md" alt="" />
                    </div>
                  </div>

                  <div className="col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <img src="/assets/images/about/ab01.jpg" className="shadow rounded-md" alt="" />
                    </div>
                  </div>
                  <div
                    className="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-indigo-600/5 after:top-0 ltr:after:-right-5 rtl:after:-left-5 after:-z-1 after:rounded-full">
                  </div>
                </div>
              </div>

              <div className="lg:col-span-7 md:col-span-6">
                <div className="ltr:lg:ml-5 rtl:lg:mr-5">
                  <h3 className="mb-6 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Online Video
                    <br /> Courses</h3>

                  <p className="text-slate-400 max-w-xl">Start working with Tailwind CSS that can provide everything you
                    need to generate awareness, drive traffic, connect. Dummy text is text that is used in the
                    publishing industry or by web designers to occupy the space which will later be filled with
                    'real' content.</p>

                  <div className="mt-6">
                    <a href=""
                      className="btn btn-link font-semibold text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Learn
                      more <i className="uil uil-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        {/* <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
            <div className="container">
              <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                <div className="lg:col-span-8 md:col-span-6 ltr:md:text-left rtl:md:text-right text-center">
                  <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Browse Online
                    Cource Categories</h3>
                  <p className="text-slate-400 max-w-xl">Search your future opportunity with our categories</p>
                </div>

                <div className="lg:col-span-4 md:col-span-6 ltr:md:text-right rtl:md:text-left hidden md:block">
                  <a href=""
                    className="btn btn-link text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">All
                    Categories <i className="uil uil-arrow-right align-middle"></i></a>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="grid grid-cols-1 relative">
                <div className="tiny-five-item">
                  <div className="tiny-slide">
                    <div
                      className="px-3 py-10 rounded-md shadow dark:shadow-gray-800 group text-center bg-white dark:bg-slate-900 hover:bg-indigo-600/5 dark:hover:bg-indigo-600/5 transition duration-500 m-2">
                      <div
                        className="w-[84px] h-[84px] bg-indigo-600/5 group-hover:bg-indigo-600 text-indigo-600 group-hover:text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition duration-500 mx-auto">
                        <i className="uil uil-gitlab"></i>
                      </div>

                      <div className="content mt-6">
                        <a href="" className="title h5 text-lg font-medium hover:text-indigo-600">UI / UX Design</a>
                        <p className="text-slate-400 mt-3">35 Lesson</p>
                      </div>
                    </div>
                  </div>

                  <div className="tiny-slide">
                    <div
                      className="px-3 py-10 rounded-md shadow dark:shadow-gray-800 group text-center bg-white dark:bg-slate-900 hover:bg-indigo-600/5 dark:hover:bg-indigo-600/5 transition duration-500 m-2">
                      <div
                        className="w-[84px] h-[84px] bg-indigo-600/5 group-hover:bg-indigo-600 text-indigo-600 group-hover:text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition duration-500 mx-auto">
                        <i className="uil uil-book-open"></i>
                      </div>

                      <div className="content mt-6">
                        <a href="" className="title h5 text-lg font-medium hover:text-indigo-600">Web Development</a>
                        <p className="text-slate-400 mt-3">20 Lesson</p>
                      </div>
                    </div>
                  </div>

                  <div className="tiny-slide">
                    <div
                      className="px-3 py-10 rounded-md shadow dark:shadow-gray-800 group text-center bg-white dark:bg-slate-900 hover:bg-indigo-600/5 dark:hover:bg-indigo-600/5 transition duration-500 m-2">
                      <div
                        className="w-[84px] h-[84px] bg-indigo-600/5 group-hover:bg-indigo-600 text-indigo-600 group-hover:text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition duration-500 mx-auto">
                        <i className="uil uil-chart-pie-alt"></i>
                      </div>

                      <div className="content mt-6">
                        <a href="" className="title h5 text-lg font-medium hover:text-indigo-600">Graphic Design</a>
                        <p className="text-slate-400 mt-3">35 Lesson</p>
                      </div>
                    </div>
                  </div>

                  <div className="tiny-slide">
                    <div
                      className="px-3 py-10 rounded-md shadow dark:shadow-gray-800 group text-center bg-white dark:bg-slate-900 hover:bg-indigo-600/5 dark:hover:bg-indigo-600/5 transition duration-500 m-2">
                      <div
                        className="w-[84px] h-[84px] bg-indigo-600/5 group-hover:bg-indigo-600 text-indigo-600 group-hover:text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition duration-500 mx-auto">
                        <i className="uil uil-feedback"></i>
                      </div>

                      <div className="content mt-6">
                        <a href="" className="title h5 text-lg font-medium hover:text-indigo-600">PHP Development</a>
                        <p className="text-slate-400 mt-3">46 Lesson</p>
                      </div>
                    </div>
                  </div>

                  <div className="tiny-slide">
                    <div
                      className="px-3 py-10 rounded-md shadow dark:shadow-gray-800 group text-center bg-white dark:bg-slate-900 hover:bg-indigo-600/5 dark:hover:bg-indigo-600/5 transition duration-500 m-2">
                      <div
                        className="w-[84px] h-[84px] bg-indigo-600/5 group-hover:bg-indigo-600 text-indigo-600 group-hover:text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition duration-500 mx-auto">
                        <i className="uil uil-presentation-line"></i>
                      </div>

                      <div className="content mt-6">
                        <a href="" className="title h5 text-lg font-medium hover:text-indigo-600">Data Science</a>
                        <p className="text-slate-400 mt-3">60 Lesson</p>
                      </div>
                    </div>
                  </div>

                  <div className="tiny-slide">
                    <div
                      className="px-3 py-10 rounded-md shadow dark:shadow-gray-800 group text-center bg-white dark:bg-slate-900 hover:bg-indigo-600/5 dark:hover:bg-indigo-600/5 transition duration-500 m-2">
                      <div
                        className="w-[84px] h-[84px] bg-indigo-600/5 group-hover:bg-indigo-600 text-indigo-600 group-hover:text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition duration-500 mx-auto">
                        <i className="uil uil-fire"></i>
                      </div>

                      <div className="content mt-6">
                        <a href="" className="title h5 text-lg font-medium hover:text-indigo-600">Digital Marketing</a>
                        <p className="text-slate-400 mt-3">05 Lesson</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-12 grid-cols-1 md:hidden mt-8">
                <div className="md:col-span-12 text-center">
                  <a href=""
                    className="btn btn-link text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">All
                    Categories <i className="uil uil-arrow-right align-middle"></i></a>
                </div>
              </div>
            </div>
          </section>
*/}


        {/* <section className="relative md:py-24 py-16 overflow-hidden">
            <div className="container">
              <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Popular Courses</h3>
                <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you
                  need to generate awareness, drive traffic, connect.</p>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">

                <div
                  className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                  <div className="relative overflow-hidden">
                    <img src="/assets/images/course/c1.jpg" className="group-hover:scale-110 duration-500 ease-in-out"
                      alt="" />
                    <div
                      className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                    </div>

                    <div
                      className="absolute ltr:left-0 rtl:right-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div className="pb-4 ltr:pl-4 rtl:pr-4 flex items-center">
                        <img src="/assets/images/client/01.jpg"
                          className="h-12 w-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                        <div className="ltr:ml-3 rtl:mr-3">
                          <a href="" className="font-semibold text-white block">Calvin Carlo</a>
                          <span className="text-white/70 text-sm">Professor</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content p-6 relative">
                    <a href="course-detail.html" className="font-medium block text-indigo-600">Digital Marketing</a>
                    <a href="course-detail.html"
                      className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">Starting
                      SEO as your Home Based Business</a>
                    <p className="text-slate-400 mt-3 mb-4">The phrasal sequence of the is now so that many campaign and
                      benefit</p>

                    <ul
                      className="pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center list-none text-slate-400">
                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-book-open text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>25 Lectures</span>
                      </li>

                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-clock text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>1h 30m</span>
                      </li>

                      <li className="flex items-center">
                        <i
                          className="uil uil-eye text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>3012</span>
                      </li>
                    </ul>

                    <div
                      className="absolute -top-7 ltr:right-6 rtl:left-6 z-1 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div
                        className="flex justify-center items-center w-14 h-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white">
                        <span className="font-semibold">$11</span>
                      </div>
                    </div>
                  </div>
                </div>



                <div
                  className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                  <div className="relative overflow-hidden">
                    <img src="/assets/images/course/c2.jpg" className="group-hover:scale-110 duration-500 ease-in-out"
                      alt="" />
                    <div
                      className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                    </div>

                    <div
                      className="absolute ltr:left-0 rtl:right-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div className="pb-4 ltr:pl-4 rtl:pr-4 flex items-center">
                        <img src="/assets/images/client/02.jpg"
                          className="h-12 w-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                        <div className="ltr:ml-3 rtl:mr-3">
                          <a href="" className="font-semibold text-white block">Christa Smith</a>
                          <span className="text-white/70 text-sm">Professor</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content p-6 relative">
                    <a href="course-detail.html" className="font-medium block text-indigo-600">Engineering</a>
                    <a href="course-detail.html"
                      className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">Java
                      Programming Masterclass for Software</a>
                    <p className="text-slate-400 mt-3 mb-4">The phrasal sequence of the is now so that many campaign and
                      benefit</p>

                    <ul
                      className="pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center list-none text-slate-400">
                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-book-open text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>25 Lectures</span>
                      </li>

                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-clock text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>1h 30m</span>
                      </li>

                      <li className="flex items-center">
                        <i
                          className="uil uil-eye text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>3012</span>
                      </li>
                    </ul>

                    <div
                      className="absolute -top-7 ltr:right-6 rtl:left-6 z-1 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div
                        className="flex justify-center items-center w-14 h-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white">
                        <span className="font-semibold">$11</span>
                      </div>
                    </div>
                  </div>
                </div>



                <div
                  className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                  <div className="relative overflow-hidden">
                    <img src="/assets/images/course/c3.jpg" className="group-hover:scale-110 duration-500 ease-in-out"
                      alt="" />
                    <div
                      className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                    </div>

                    <div
                      className="absolute ltr:left-0 rtl:right-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div className="pb-4 ltr:pl-4 rtl:pr-4 flex items-center">
                        <img src="/assets/images/client/03.jpg"
                          className="h-12 w-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                        <div className="ltr:ml-3 rtl:mr-3">
                          <a href="" className="font-semibold text-white block">Jani Jangad</a>
                          <span className="text-white/70 text-sm">Professor</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content p-6 relative">
                    <a href="course-detail.html" className="font-medium block text-indigo-600">Engineering</a>
                    <a href="course-detail.html"
                      className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">Microsoft
                      Excel - Excel from Beginner to Advanced</a>
                    <p className="text-slate-400 mt-3 mb-4">The phrasal sequence of the is now so that many campaign and
                      benefit</p>

                    <ul
                      className="pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center list-none text-slate-400">
                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-book-open text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>25 Lectures</span>
                      </li>

                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-clock text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>1h 30m</span>
                      </li>

                      <li className="flex items-center">
                        <i
                          className="uil uil-eye text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>3012</span>
                      </li>
                    </ul>

                    <div
                      className="absolute -top-7 ltr:right-6 rtl:left-6 z-1 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div
                        className="flex justify-center items-center w-14 h-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white">
                        <span className="font-semibold">$11</span>
                      </div>
                    </div>
                  </div>
                </div>



                <div
                  className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                  <div className="relative overflow-hidden">
                    <img src="/assets/images/course/c4.jpg" className="group-hover:scale-110 duration-500 ease-in-out"
                      alt="" />
                    <div
                      className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                    </div>

                    <div
                      className="absolute ltr:left-0 rtl:right-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div className="pb-4 ltr:pl-4 rtl:pr-4 flex items-center">
                        <img src="/assets/images/client/04.jpg"
                          className="h-12 w-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                        <div className="ltr:ml-3 rtl:mr-3">
                          <a href="" className="font-semibold text-white block">John Cartwright</a>
                          <span className="text-white/70 text-sm">Professor</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content p-6 relative">
                    <a href="course-detail.html" className="font-medium block text-indigo-600">Web Development</a>
                    <a href="course-detail.html"
                      className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">Vue - The
                      Complete Guide (w/ Router, Composition API)</a>
                    <p className="text-slate-400 mt-3 mb-4">The phrasal sequence of the is now so that many campaign and
                      benefit</p>

                    <ul
                      className="pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center list-none text-slate-400">
                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-book-open text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>25 Lectures</span>
                      </li>

                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-clock text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>1h 30m</span>
                      </li>

                      <li className="flex items-center">
                        <i
                          className="uil uil-eye text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>3012</span>
                      </li>
                    </ul>

                    <div
                      className="absolute -top-7 ltr:right-6 rtl:left-6 z-1 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div
                        className="flex justify-center items-center w-14 h-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white">
                        <span className="font-semibold">$11</span>
                      </div>
                    </div>
                  </div>
                </div>



                <div
                  className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                  <div className="relative overflow-hidden">
                    <img src="/assets/images/course/c5.jpg" className="group-hover:scale-110 duration-500 ease-in-out"
                      alt="" />
                    <div
                      className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                    </div>

                    <div
                      className="absolute ltr:left-0 rtl:right-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div className="pb-4 ltr:pl-4 rtl:pr-4 flex items-center">
                        <img src="/assets/images/client/05.jpg"
                          className="h-12 w-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                        <div className="ltr:ml-3 rtl:mr-3">
                          <a href="" className="font-semibold text-white block">Sally Short</a>
                          <span className="text-white/70 text-sm">Professor</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content p-6 relative">
                    <a href="course-detail.html" className="font-medium block text-indigo-600">Front-end Design</a>
                    <a href="course-detail.html"
                      className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">HTML, CSS,
                      Javascripts, and Basic Knowledge about web design</a>
                    <p className="text-slate-400 mt-3 mb-4">The phrasal sequence of the is now so that many campaign and
                      benefit</p>

                    <ul
                      className="pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center list-none text-slate-400">
                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-book-open text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>25 Lectures</span>
                      </li>

                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-clock text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>1h 30m</span>
                      </li>

                      <li className="flex items-center">
                        <i
                          className="uil uil-eye text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>3012</span>
                      </li>
                    </ul>

                    <div
                      className="absolute -top-7 ltr:right-6 rtl:left-6 z-1 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div
                        className="flex justify-center items-center w-14 h-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white">
                        <span className="font-semibold">$11</span>
                      </div>
                    </div>
                  </div>
                </div>



                <div
                  className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                  <div className="relative overflow-hidden">
                    <img src="/assets/images/course/c6.jpg" className="group-hover:scale-110 duration-500 ease-in-out"
                      alt="" />
                    <div
                      className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                    </div>

                    <div
                      className="absolute ltr:left-0 rtl:right-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div className="pb-4 ltr:pl-4 rtl:pr-4 flex items-center">
                        <img src="/assets/images/client/06.jpg"
                          className="h-12 w-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                        <div className="ltr:ml-3 rtl:mr-3">
                          <a href="" className="font-semibold text-white block">William Benson</a>
                          <span className="text-white/70 text-sm">Professor</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content p-6 relative">
                    <a href="course-detail.html" className="font-medium block text-indigo-600">Back-end Development</a>
                    <a href="course-detail.html"
                      className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">C
                      Programming For Beginners - Master the C Language</a>
                    <p className="text-slate-400 mt-3 mb-4">The phrasal sequence of the is now so that many campaign and
                      benefit</p>

                    <ul
                      className="pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center list-none text-slate-400">
                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-book-open text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>25 Lectures</span>
                      </li>

                      <li className="flex items-center ltr:mr-4 rtl:ml-4">
                        <i
                          className="uil uil-clock text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>1h 30m</span>
                      </li>

                      <li className="flex items-center">
                        <i
                          className="uil uil-eye text-lg leading-none ltr:mr-2 rtl:ml-2 text-slate-900 dark:text-white"></i>
                        <span>3012</span>
                      </li>
                    </ul>

                    <div
                      className="absolute -top-7 ltr:right-6 rtl:left-6 z-1 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                      <div
                        className="flex justify-center items-center w-14 h-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white">
                        <span className="font-semibold">$11</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                <div className="md:col-span-12 text-center">
                  <a href="course-listing.html"
                    className="btn btn-link text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">See
                    All Courses <i className="uil uil-arrow-right align-middle"></i></a>
                </div>
              </div>
            </div>
          </section> */}



        <section className="relative md:py-24 py-16 bg-no-repeat bg-fixed bg-top"
          style={{ backgroundImage: "url(assets/images/course/cta.jpg)" }}
          id="table">
          <div className="absolute inset-0 bg-black/50"></div>
          <div className="container">
            <div className="grid lg:grid-cols-12 md:grid-cols-2 items-center gap-[30px]">
              <div className="lg:col-span-7">
                <h4 className="font-semibold lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5 text-white">Register
                  Now !</h4>

                <p className="text-white/70 text-lg max-w-xl">Launch your campaign and benefit from our expertise on
                  designing and managing conversion centered Tailwind CSS v3.x html page.</p>

                <div className="relative mt-10">
                  <a href=""
                    className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Admission
                    Now</a>
                </div>
              </div>

              <div className="lg:col-span-5">
                <div
                  className="bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-800 md:p-8 p-6 ltr:lg:ml-10 rtl:lg:mr-10 z-10 relative">
                  <h4 className="mb-5 text-2xl font-semibold">Register Now</h4>

                  <form>
                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label className="form-label font-medium">Your Name : <span
                          className="text-red-600">*</span></label>
                        <div className="form-icon relative mt-2">
                          <i data-feather="user" className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"></i>
                          <input type="text" className="form-input ltr:pl-12 rtl:pr-12" placeholder="Name"
                            name="name" required />
                        </div>
                      </div>

                      <div className="mb-5">
                        <label className="form-label font-medium">Your Email : <span
                          className="text-red-600">*</span></label>
                        <div className="form-icon relative mt-2">
                          <i data-feather="mail" className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"></i>
                          <input type="email" className="form-input ltr:pl-12 rtl:pr-12" placeholder="Email"
                            name="email" required />
                        </div>
                      </div>

                      <div className="mb-5">
                        <label className="form-label font-medium">Phone No. : <span
                          className="text-red-600">*</span></label>
                        <div className="form-icon relative mt-2">
                          <i data-feather="phone" className="w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4"></i>
                          <input name="number" id="phNumber" className="form-input ltr:pl-12 rtl:pr-12"
                            placeholder="+12 12458 854" required />
                        </div>
                      </div>

                      <div>
                        <a href=""
                          className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Free
                          Trial</a>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="relative md:py-24 py-16 overflow-hidden">
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Community</h3>

              <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you
                need to generate awareness, drive traffic, connect.</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="grid grid-cols-1 mt-8">
              <div
                className="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:left-0 before:z-2 after:content-[''] after:absolute after:top-0 after:right-0 after:z-2">
                <div className="slide-track flex items-center">
                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/01.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Thomas
                              Israel</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I didn't know a thing about icon design until I read this
                            book. Now I can create any icon I need in no time. Great resource!</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/05.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">There are so many things I had to do with my old software
                            that I just don't do at all with Techwind. Suspicious but I can't say I don't
                            love it.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/02.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Carl
                              Oliver</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">The best part about Techwind is every time I pay my
                            employees, my bank balance doesn't go down like it used to. Looking forward to
                            spending this extra cash when I figure out why my card is being declined.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/04.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Jill
                              Webb</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I'm trying to get a hold of someone in support, I'm in a
                            lot of trouble right now and they are saying it has something to do with my
                            books. Please get back to me right away.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/03.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I used to have to remit tax to the EU and with Techwind I
                            somehow don't have to do that anymore. Nervous to travel there now though.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/06.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Janisha
                              Doll</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">This is the fourth email I've sent to your support team. I
                            am literally being held in jail for tax fraud. Please answer your damn emails,
                            this is important.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/01.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Thomas
                              Israel</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I didn't know a thing about icon design until I read this
                            book. Now I can create any icon I need in no time. Great resource!</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/05.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">There are so many things I had to do with my old software
                            that I just don't do at all with Techwind. Suspicious but I can't say I don't
                            love it.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/02.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Carl
                              Oliver</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">The best part about Techwind is every time I pay my
                            employees, my bank balance doesn't go down like it used to. Looking forward to
                            spending this extra cash when I figure out why my card is being declined.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/04.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Jill
                              Webb</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I'm trying to get a hold of someone in support, I'm in a
                            lot of trouble right now and they are saying it has something to do with my
                            books. Please get back to me right away.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/03.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I used to have to remit tax to the EU and with Techwind I
                            somehow don't have to do that anymore. Nervous to travel there now though.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/06.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Janisha
                              Doll</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">This is the fourth email I've sent to your support team. I
                            am literally being held in jail for tax fraud. Please answer your damn emails,
                            this is important.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/01.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Thomas
                              Israel</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I didn't know a thing about icon design until I read this
                            book. Now I can create any icon I need in no time. Great resource!</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/05.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">There are so many things I had to do with my old software
                            that I just don't do at all with Techwind. Suspicious but I can't say I don't
                            love it.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/02.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Carl
                              Oliver</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">The best part about Techwind is every time I pay my
                            employees, my bank balance doesn't go down like it used to. Looking forward to
                            spending this extra cash when I figure out why my card is being declined.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/04.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Jill
                              Webb</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I'm trying to get a hold of someone in support, I'm in a
                            lot of trouble right now and they are saying it has something to do with my
                            books. Please get back to me right away.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/03.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I used to have to remit tax to the EU and with Techwind I
                            somehow don't have to do that anymore. Nervous to travel there now though.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/06.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Janisha
                              Doll</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">This is the fourth email I've sent to your support team. I
                            am literally being held in jail for tax fraud. Please answer your damn emails,
                            this is important.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/01.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Thomas
                              Israel</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I didn't know a thing about icon design until I read this
                            book. Now I can create any icon I need in no time. Great resource!</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/05.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">There are so many things I had to do with my old software
                            that I just don't do at all with Techwind. Suspicious but I can't say I don't
                            love it.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/02.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Carl
                              Oliver</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">The best part about Techwind is every time I pay my
                            employees, my bank balance doesn't go down like it used to. Looking forward to
                            spending this extra cash when I figure out why my card is being declined.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/04.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Jill
                              Webb</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I'm trying to get a hold of someone in support, I'm in a
                            lot of trouble right now and they are saying it has something to do with my
                            books. Please get back to me right away.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/03.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I used to have to remit tax to the EU and with Techwind I
                            somehow don't have to do that anymore. Nervous to travel there now though.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/06.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Janisha
                              Doll</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">This is the fourth email I've sent to your support team. I
                            am literally being held in jail for tax fraud. Please answer your damn emails,
                            this is important.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/01.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Thomas
                              Israel</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I didn't know a thing about icon design until I read this
                            book. Now I can create any icon I need in no time. Great resource!</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/05.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">There are so many things I had to do with my old software
                            that I just don't do at all with Techwind. Suspicious but I can't say I don't
                            love it.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/02.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Carl
                              Oliver</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">The best part about Techwind is every time I pay my
                            employees, my bank balance doesn't go down like it used to. Looking forward to
                            spending this extra cash when I figure out why my card is being declined.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/04.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Jill
                              Webb</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I'm trying to get a hold of someone in support, I'm in a
                            lot of trouble right now and they are saying it has something to do with my
                            books. Please get back to me right away.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/03.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I used to have to remit tax to the EU and with Techwind I
                            somehow don't have to do that anymore. Nervous to travel there now though.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/06.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Janisha
                              Doll</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">This is the fourth email I've sent to your support team. I
                            am literally being held in jail for tax fraud. Please answer your damn emails,
                            this is important.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/01.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Thomas
                              Israel</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I didn't know a thing about icon design until I read this
                            book. Now I can create any icon I need in no time. Great resource!</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/05.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">There are so many things I had to do with my old software
                            that I just don't do at all with Techwind. Suspicious but I can't say I don't
                            love it.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/02.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Carl
                              Oliver</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">The best part about Techwind is every time I pay my
                            employees, my bank balance doesn't go down like it used to. Looking forward to
                            spending this extra cash when I figure out why my card is being declined.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/04.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href="" className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Jill
                              Webb</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I'm trying to get a hold of someone in support, I'm in a
                            lot of trouble right now and they are saying it has something to do with my
                            books. Please get back to me right away.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="slide h-auto md:w-[360px] w-72 m-2">
                    <ul className="space-y-4">
                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/03.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Barbara
                              McIntosh</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">I used to have to remit tax to the EU and with Techwind I
                            somehow don't have to do that anymore. Nervous to travel there now though.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>

                      <li className="rounded-lg shadow dark:shadow-gray-800 p-6">
                        <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                          <img src="/assets/images/client/06.jpg"
                            className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                          <div className="ltr:pl-4 rtl:pr-4">
                            <a href=""
                              className="text-lg hover:text-indigo-600 duration-500 ease-in-out">Janisha
                              Doll</a>
                            <p className="text-slate-400">Student</p>
                          </div>
                        </div>

                        <div className="mt-6">
                          <p className="text-slate-400">This is the fourth email I've sent to your support team. I
                            am literally being held in jail for tax fraud. Please answer your damn emails,
                            this is important.</p>
                          <ul className="list-none mb-0 text-amber-400 mt-2">
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                            <li className="inline"><i className="mdi mdi-star"></i></li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Instructor</h3>

              <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you
                need to generate awareness, drive traffic, connect.</p>
            </div>

            <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
              <div className="lg:col-span-3 md:col-span-6">
                <div className="group text-center">
                  <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                    <img src="/assets/images/client/04.jpg" className="" alt="" />
                    <div
                      className="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out">
                    </div>

                    <ul
                      className="list-none absolute right-0 left-0 -bottom-20 group-hover:bottom-5 transition-all duration-500 ease-in-out">
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="facebook" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="instagram" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="linkedin" className="h-4 w-4"></i></a></li>
                    </ul>
                  </div>

                  <div className="content mt-3">
                    <a href=""
                      className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out">Jack
                      John</a>
                    <p className="text-slate-400">Professor</p>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-3 md:col-span-6">
                <div className="group text-center">
                  <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                    <img src="/assets/images/client/05.jpg" className="" alt="" />
                    <div
                      className="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out">
                    </div>

                    <ul
                      className="list-none absolute right-0 left-0 -bottom-20 group-hover:bottom-5 transition-all duration-500 ease-in-out">
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="facebook" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="instagram" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="linkedin" className="h-4 w-4"></i></a></li>
                    </ul>
                  </div>

                  <div className="content mt-3">
                    <a href=""
                      className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out">Krista
                      John</a>
                    <p className="text-slate-400">Professor</p>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-3 md:col-span-6">
                <div className="group text-center">
                  <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                    <img src="/assets/images/client/06.jpg" className="" alt="" />
                    <div
                      className="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out">
                    </div>

                    <ul
                      className="list-none absolute right-0 left-0 -bottom-20 group-hover:bottom-5 transition-all duration-500 ease-in-out">
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="facebook" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="instagram" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="linkedin" className="h-4 w-4"></i></a></li>
                    </ul>
                  </div>

                  <div className="content mt-3">
                    <a href=""
                      className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out">Roger
                      Jackson</a>
                    <p className="text-slate-400">Professor</p>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-3 md:col-span-6">
                <div className="group text-center">
                  <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                    <img src="/assets/images/client/07.jpg" className="" alt="" />
                    <div
                      className="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out">
                    </div>

                    <ul
                      className="list-none absolute right-0 left-0 -bottom-20 group-hover:bottom-5 transition-all duration-500 ease-in-out">
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="facebook" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="instagram" className="h-4 w-4"></i></a></li>
                      <li className="inline"><a href=""
                        className="btn btn-icon btn-sm rounded-full border border-indigo-600 bg-indigo-600 hover:border-indigo-600 hover:bg-indigo-600 text-white"><i
                          data-feather="linkedin" className="h-4 w-4"></i></a></li>
                    </ul>
                  </div>

                  <div className="content mt-3">
                    <a href=""
                      className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out">Johnny
                      English</a>
                    <p className="text-slate-400">Professor</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container md:mt-24 mt-16">
              <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Blog Or News</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you
                  need to generate awareness, drive traffic, connect.</p>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                  <img src="/assets/images/blog/01.jpg" alt="" />

                  <div className="content p-6">
                    <a href="blog-detail.html"
                      className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Design your
                      apps in your own way</a>
                    <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit
                    </p>

                    <div className="mt-4">
                      <a href="blog-detail.html"
                        className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read
                        More <i className="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                </div>

                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                  <img src="/assets/images/blog/02.jpg" alt="" />

                  <div className="content p-6">
                    <a href="blog-detail.html"
                      className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">How apps is
                      changing the IT world</a>
                    <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit
                    </p>

                    <div className="mt-4">
                      <a href="blog-detail.html"
                        className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read
                        More <i className="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                </div>

                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                  <img src="/assets/images/blog/03.jpg" alt="" />

                  <div className="content p-6">
                    <a href="blog-detail.html"
                      className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Smartest
                      Applications for Business</a>
                    <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit
                    </p>

                    <div className="mt-4">
                      <a href="blog-detail.html"
                        className="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read
                        More <i className="uil uil-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </section>

      </Layout>
    </React.Fragment>
  )
}

export default Home
